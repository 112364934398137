@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

@font-face {
    font-family: "GILROY";
    src: url("../assets/font/Gilroy-Light.otf");
  }

  @font-face {
    font-family: "GILROYY";
    src: url("../assets/font/Gilroy-ExtraBold.otf");
  }
